
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const formatTools = require("../../../lib/formatTools")
const webappsAPI = require("../../../lib/requestWebapps");


const ENTITYID="nnsellerlocation"


const apijwtuserpickerfields = [
	{"label":"Portal", "dbfield": "apijwtuserprovider_name", "type": "text", "filtertype": "textbox"},
	{"label":"Name", "dbfield": "apijwtuser_name", "type": "text", "filtertype": "textbox"},
	{"label":"email", "dbfield": "apijwtuser_identifier", "type": "text", "filtertype": "textbox"}
];

const apijwtuserpickerfilter = [
	//{"field":"onsorganization_branchid", "operation": ">", "value": 1},
];

const nnpickuppickerfields = [
	{"label":"Name", "dbfield": "nnpickup_name", "type": "text", "filtertype": "textbox"},
	{"label":"Address", "dbfield": "nnpickup_strtaddress", "type": "text", "filtertype": "textbox"}
];

const nnpickuppickerfilter = [
	//3 = pick up point
	{"field":"onsorderformfulfillment_id", "operation": "=", "value": 3},
];


const searchFields = [
	{"label":"Updated", "dbfield": "nnsellerlocation_updated", "type": "datetime", "filtertype": "datetime"},
	{"label":"Portal", "dbfield": "apijwtuserprovider_name", "type": "text", "filtertype": "text"},
	{"label":"Name", "dbfield": "apijwtuser_name", "type": "text", "filtertype": "text"},
	{"label":"Location", "dbfield": "nnpickup_name", "type": "text", "filtertype": "text"},
	{"label":"Notes", "dbfield": "nnsellerlocation_notes", "type": "text", "filtertype": "text"},
];


const formFields = [
	{"label":"Updated", "field": "nnsellerlocation_updated", "value": "", "input": "datetime", "mode": "readonly"},
	{"label":"Portal", "field": "apijwtuserprovider_name", "value": "", "input": "picker", "mode": "readonly"},
	{"label":"portalid", "field": "apijwtuserprovider_id", "value": "", "input": "hidden", "mode": "required"},
	{"label":"Name", "field": "apijwtuser_name", "value": "", "input": "picker", "mode": "unique", "pickerfields": apijwtuserpickerfields, "pickerfilters": apijwtuserpickerfilter},
	{"label":"email", "field": "apijwtuser_identifier", "value": "", "input": "textbox", "mode": "readonly"},
	{"label":"Location", "field": "nnpickup_name", "value": "", "input": "picker", "mode": "required", "pickerfields": nnpickuppickerfields, "pickerfilters": nnpickuppickerfilter},
	{"label":"Notes", "field": "nnsellerlocation_notes", "value": "", "input": "textarea", "mode": "normal"},

];


const UserLocationPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [documentid, setDocumentid] = useState(0);


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		params.nnsellerlocation_updated=formatTools.getDateStr();

		//callback({"status":"Error", "message":JSON.stringify(params)}); return;

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, callback);
	}


	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {}
		// Add detaching/handling of parent product
		if (documentid > 0) {
			const readonlylist = ["apijwtuser_name"];

			if (readonlylist.includes(inputlist[idx].field)) {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
		}
		return inputlist[idx];
	}

	function customSetFormData(newformdata)
	{
		var newdocid = 0;
		if (newformdata.hasOwnProperty(ENTITYID+"_id")) {
			if (newformdata[ENTITYID+"_id"]) {
				newdocid = newformdata[ENTITYID+"_id"];
			}
		}

		setDocumentid(newdocid);
	}

	return <Layout
			fullPath={location.pathname}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={true}
				allowdelete={true}
				entity={ENTITYID}
				mobilerowtitlefield={["apijwtuserprovider_name","apijwtuser_name"]}
				searchFields={searchFields}
				editFields={[formFields]}

				customSubmit={customSubmit}
				customSetFormData={customSetFormData}
				customEditFieldInfo={customEditFieldInfo}

				token={token} />
		</Layout>

}


export default UserLocationPage;
